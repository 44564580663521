html {
    overflow-x: hidden !important;
}

body {
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    color: #424242;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    background-color: #fff;
	// font-family: "Proxima Nova", sans-serif;
	font-family: proxima-nova,sans-serif;
    overflow-x: hidden !important;
}


/*
/*-----------------------------------------------------------------------------------*/


/*	RESET
/*-----------------------------------------------------------------------------------*/

*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

:focus {
    outline: 0 !important;
}

iframe {
    border: none;
    width: 100%;
}

::selection {
    background: #92deff;
    /* Safari */
    color: #555555;
}

::-moz-selection {
    background: #92deff;
    /* Firefox */
    color: #555555;
}

strong,
b {
    font-family: proxima-nova,sans-serif;
    font-weight: 700;
}

input,
button,
select,
textarea,
label {
	// font-family: "Proxima Nova", sans-serif;
	font-family: proxima-nova,sans-serif;
    font-size: 14px;
    font-weight: 400;
}

hr {
    background-image: -webkit-linear-gradient(left, transparent, rgba(0, 0, 0, 0.2), transparent);
    background-image: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.2), transparent);
    border: 0;
    height: 1px;
    margin: 22px 0 22px 0;
}

.badge {
    font-weight: normal;
    margin-left: 5px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    border-radius: 0px;
    background-color: #26ace2;
    padding: 3px 6px;
}

a {
    color: #333;
    -moz-transition: all 200ms ease-in;
    -o-transition: all 200ms ease-in;
    -webkit-transition: all 200ms ease-in;
    transition: all 200ms ease-in;
}

a:hover,
a:focus {
    color: #26ace2;
    text-decoration: none;
}

.colored-text {
    color: #26ace2;
}


/* Headings */

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #060606;
    font-weight: 700;
    margin: 0;
	// font-family: "Proxima Nova", sans-serif;
	font-family: proxima-nova,sans-serif;
}

h1 {
    font-size: 3.2em;
    line-height: 44px;
    margin: 0 0 44px 0;
}

h2 {
    font-size: 2.6em;
    font-weight: 700;
    line-height: 42px;
    margin: 0 0 32px 0;
}

h3 {
    font-size: 1.8em;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 24px;
    margin-bottom: 15px;
}


/*use in section headings*/

h3.heading {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    font-style: normal;
    margin: 0 0 10px;
    padding-bottom: 0px;
    position: relative;
    text-transform: capitalize;
    overflow: hidden;
    vertical-align: middle;
	// font-family: "Proxima Nova", sans-serif;
	font-family: proxima-nova,sans-serif;
}

h3.heading:after {
    position: relative;
    left: 18px;
    content: "";
    display: inline-block;
    width: 200%;
    vertical-align: middle;
    height: .50em;
    margin: 0 -200% 0 0;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}

h4 {
    font-size: 1.4em;
    font-weight: 700;
    letter-spacing: normal;
    line-height: 27px;
    margin: 0 0 14px 0;
}

h5 {
    font-size: 1em;
    font-weight: 700;
    letter-spacing: normal;
    line-height: 18px;
    margin: 0 0 14px 0;
}

h6 {
    color: #333;
    font-size: 1em;
    font-weight: 700;
    letter-spacing: normal;
    line-height: 18px;
    margin: 0 0 14px 0;
}


/*parahraphs*/

p {
    color: #555;
    line-height: 24px;
    margin: 0 0 20px;
}

p.sub-text {
    font-style: normal;
    font-size: 16px;
    line-height: 29px;
    font-weight: 300;
    color: #555;
}

p.lead {
    font-size: 16px;
    font-weight: 400;
}


/*-----------------------------------------------------------------------------------*/


/*	DIVIDERS
/*-----------------------------------------------------------------------------------*/

.divide0 {
    width: 100%;
    height: 0px;
}

.divide2 {
    width: 100%;
    height: 2px;
}

.divide3 {
    width: 100%;
    height: 3px;
}

.divide5 {
    width: 100%;
    height: 5px;
}

.divide10 {
    width: 100%;
    height: 10px;
}

.divide15 {
    width: 100%;
    height: 15px;
}

.divide20 {
    width: 100%;
    height: 20px;
}

.divide25 {
    width: 100%;
    height: 25px;
}

.divide30 {
    width: 100%;
    height: 30px;
}

.divide35 {
    width: 100%;
    height: 35px;
}

.divide40 {
    width: 100%;
    height: 40px;
}

.divide45 {
    width: 100%;
    height: 40px;
}

.divide50 {
    width: 100%;
    height: 50px;
}

.divide55 {
    width: 100%;
    height: 55px;
}

.divide60 {
    width: 100%;
    height: 60px;
}

.divide65 {
    width: 100%;
    height: 65px;
}

.divide70 {
    width: 100%;
    height: 70px;
}

.divide75 {
    width: 100%;
    height: 75px;
}

.divide80 {
    width: 100%;
    height: 80px;
}

.divide85 {
    width: 100%;
    height: 85px;
}

.divide90 {
    width: 100%;
    height: 90px;
}

.divide95 {
    width: 100%;
    height: 95px;
}

.divide100 {
    width: 100%;
    height: 100px;
}


/*margins*/

.margin10 {
    margin-bottom: 10px;
}

.margin20 {
    margin-bottom: 20px;
}

.margin30 {
    margin-bottom: 30px;
}

.margin40 {
    margin-bottom: 40px;
}

.margin50 {
    margin-bottom: 50px;
}

.no-padding {
    padding: 0px;
}

.no-padding.gray {
    background-color: #f5f5f5;
}

.no-padding-inner h3 {
    text-transform: uppercase;
    font-weight: 700;
}

.no-padding-inner {
    padding: 60px;
}

@media (max-width: 568px) {
    .no-padding-inner {
        padding: 15px;
    }
}

.margin-0 {
    margin: 0px !important;
}

.gray-box {
    background-color: #f9fafd;
    padding-bottom: 40px;
    padding-top: 70px;
}


/*************************************************************/


/************************** Buttons **************************/


/*************************************************************/

.btn {
    font-weight: 400;
    letter-spacing: 0px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    border-radius: 2px;
    font-size: 14px;
    text-transform: uppercase;
}

.btn-default:hover,
.btn-default:focus {
    background-color: #333;
    border-color: #333;
    color: #fff;
}

.btn-lg {
    padding: 14px 28px 13px 28px;
    font-size: 15px;
    font-weight: 400;
}

.btn:focus,
.btn:hover {
    outline: 0;
    color: #fff;
}

.btn-xs {
    font-size: 12px;
}

.btn-theme-bg {
    border-color: #26ace2;
    color: #fff;
    background-color: #26ace2;
}

.btn i {
    margin-right: 10px;
}

.btn-theme-bg:hover {
    background-color: #333;
    border-color: #333;
}

.btn-theme-dark {
    background-color: #333;
    color: #fff;
}

.btn-theme-dark:hover {
    color: #fff;
    background-color: #26ace2;
}


/*********border-buttons**********/

.border-black {
    border: 1px solid #333;
    color: #000;
    background-color: transparent;
}

.border-black:hover {
    background-color: #000;
    border-color: #000;
    color: #fff;
}

.border-white {
    border: 1px solid #fff;
    color: #fff;
}

.border-white:hover {
    background-color: #fff;
    border-color: #fff;
    color: #000;
}

.border-theme {
    border: 1px solid #26ace2;
    color: #26ace2;
}

.border-theme:hover {
    background-color: #26ace2;
    border-color: #26ace2;
    color: #fff;
}

.btn-3d {
    border-bottom: 3px solid rgba(0, 0, 0, 0.3);
}

.btn-ico i {
    margin-left: 5px;
}


/***********bootstrap navigation default overrides--*/

.navbar-default {
    border: none;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    border-radius: 0px;
    margin-bottom: 0;
    width: 100%;
    min-height: 70px;
	padding: 10px 0;
	
    -webkit-box-shadow: rgba(0, 0, 0, 0.10) 0px 10px 20px 0px;
    -moz-box-shadow: rgba(0, 0, 0, 0.10) 0px 10px 20px 0px;
    -ms-box-shadow: rgba(0, 0, 0, 0.10) 0px 10px 20px 0px;
    box-shadow: rgba(0, 0, 0, 0.10) 0px 10px 20px 0px;
	
	background-color: rgba(255, 255, 255, 0.9);
    clear: both;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    background: #ffffff;
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:hover,
.navbar-default .navbar-nav>.active>a:focus {
    color: #26ace2;
    background-color: transparent;
    font-weight: 600;
    background: none;
    border: none;
    box-shadow: none;
}

.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:hover,
.navbar-default .navbar-nav>.open>a:focus {
    color: #26ace2;
    background-color: transparent;
}

.navbar-brand {
    font-weight: 700;
    color: #000 !important;
    font-size: 30px;
    line-height: 20px;
    font-style: normal;
    text-transform: uppercase;
    height: auto;
}

.navbar-default .navbar-nav>li>a {
    color: #3d3d3d;
    font-size: 15px;
    font-weight: 400;
    padding: 11px 8px;
}

.navbar-default .navbar-nav>li>a.btn-theme-bg-ext {
    border: 1px solid #26ACE2;
    background-color: #FFFFFF;
    box-shadow: 0 5px 10px 0px rgba(38, 172, 226, 0.2);
    border-radius: 5px;
    color: #26ace2;
    font-size: 12px;
    padding: 8px 8px;
    margin-left: 11px;
    font-weight: 600;
}

.navbar a.btn-theme-bg-ext:hover,
.navbar li.active a.btn-theme-bg-ext {
    background: #26ace2 !important;
    color: #fff !important;
}

.navbar-nav {
    padding-top: 20px;
}

.navbar-default .navbar-nav>li>a:hover {
    color: #26ace2;
}

.navbar-brand img {
    width: 80px;
    height: auto;
}


/*============================*/


/*======dropdowm menu=========*/


/*============================*/

.navbar .dropdown-menu {
    padding: 0px;
    margin: 0;
    min-width: 200px;
    background-color: #fff;
    border: 0;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    border-radius: 0px;
    border: 1px solid #ddd;
}

.search-dropdown {
    min-width: 244px !important;
}

.navbar .dropdown-menu li a {
    color: #777;
    font-size: 15px;
    border-bottom: 1px solid #f5f5f5;
    padding: 6px 16px;
    line-height: 1.42857143;
    text-transform: capitalize;
}

.dropdown-menu .label {
    margin-top: 6px;
}

.navbar .dropdown-menu li a:hover {
    background-color: #f5f5f5;
}

.navbar-nav {
    padding-top: 15px;
}

.navbar-default .navbar-brand {
    padding: 0;
    margin: 0;
}

.navbar-brand img {
    width: 140px;
}


/*forms*/

select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.form-control {
    border: 1px solid #ddd;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    font-size: 12px;
    color: #7a7a7a;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    box-shadow: none;
    background: #FFF;
    -moz-transition: all 200ms ease-in;
    -o-transition: all 200ms ease-in;
    -webkit-transition: all 200ms ease-in;
    transition: all 200ms ease-in;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.form-control:focus {
    border-color: #bbb;
    background: #FFF;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    box-shadow: none;
}


/* Navigation */

.sticky-wrapper {
    position: absolute;
    width: 100%;
}


/* Home header section */
.home-header {
	.content {
		height: 100vh;
		background: url(../resources/images/page-title/pos-ecosystem-graphic.jpg);
		background-size: 60%;
		background-repeat: no-repeat;
		background-position: center right;
		.text {
			margin: 0;
			position: absolute;
			top: 50%;
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
			h1 {
				padding: 0 0 20px 0;
				margin: 0;
				font-weight: 300;
				color: #1175A2;
				font-size: 90px;
				line-height: 90px;
			}
			h2 {
				font-weight: 400;
				color: #000000;
				font-size: 36px;
				margin-bottom: 60px;
			}
			.graphic {
				margin: 0 auto;
				max-width: 90%;
				text-align: center;
				img {
					display: none;
				}
			}
			a.button {
				display: block;
				width: 233px;
				height: 55px;
				text-align: center;
				background-color: #26ace2;
				box-shadow: 0 5px 10px 0 rgba(38, 172, 226, .2);
				border-radius: 5px;
				color: #fff;
				font-weight: 600;
				font-size: 16px;
				padding: 15px 0;
				margin-top: 28px;
				border: 1px solid #26ace2;
			}
		}
		@media (max-width: 1999px) {
			background-size: 50%;
		}
		@media (max-width: 991px) {
			background: none;
			height: auto;
			padding-top: 200px;
			padding-bottom: 40px;
			.text {
				position: relative;
				top: 0;
				-ms-transform: translateY(0);
				transform: translateY(0);
				h1 {

				}
				h2 {

				}
				.graphic {
					img {
						display: block;
						margin: 0 auto;
					}
				}
			}
		}
		@media (max-width: 767px) {
			padding: 120px 30px 40px 30px;
			.text {
				h1 {
					font-size: 60px;
					line-height: 60px;
					padding-bottom: 20px;
					margin-bottom: 0;
				}
				h2 {
					font-size: 26px;
				}
				.graphic {
					img {
						display: block;
						margin: 0 auto;
					}
				}
				a.button {
					margin-top: 50px;
					width: 100%;
				}
			}
		}
	}
}

.home-header-section-holder {
    background: #ffffff;
}

.home-header-section {
    background: url(../resources/images/main-background.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 0 0 70% 70%;
    margin-left: -10%;
    margin-right: -10%;
	overflow: hidden;
	perspective: 1000px;
	-webkit-perspective: 1000px;
}

.home-header-section .z-index {
    z-index: 2;
}

.home-header-section .content {
    margin-top: 216px;
}

.home-header-section .content p {
    margin: 0;
    padding: 0;
    color: #26ace2;
    font-size: 24px;
    line-height: 24px;
    text-transform: uppercase;
    letter-spacing: 1.70px;
    text-align: center;
}

.home-header-section .content h1 {
    margin: 0;
    padding: 0;
    font-weight: 600;
    color: #ffffff;
    font-size: 72px;
    line-height: 72px;
    text-transform: uppercase;
    text-align: center;
}

.home-header-section .content a {
    display: block;
    width: 233px;
    height: 55px;
    margin: 0 auto;
    text-align: center;
    background-color: #26ace2;
    box-shadow: 0 5px 10px 0 rgba(38, 172, 226, .2);
    border-radius: 5px;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    padding: 15px 0;
    margin-top: 28px;
    border: 1px solid #26ace2;
}

.home-header-section .content a:hover {
    background-color: #ffffff;
    color: #26ace2;
}

.home-header-section .bg-home-page {
    background: url(../resources/images/home-spidometer.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    height: 490px;
    width: 100%;
    margin-top: -20px;
    z-index: 1;
}


/* Other pages header section */

.header-section-holder {
    position: relative;
}

.header-section-holder .header-section {
    background: url(../resources/images/main-background.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 0 0 70% 70%;
    margin-left: -10%;
    margin-right: -10%;
    overflow: hidden;
    min-height: 436px;
	max-height: 436px;
	perspective: 1000px;
	-webkit-perspective: 1000px;
}

.header-section-holder .header-section .z-index {
    z-index: 2;
}

.header-section-holder .header-section .content {
    margin-top: 140px;
}

.header-section-holder .header-section .content h1.header-title {
    margin: 0;
    padding: 0;
    padding-bottom: 14px;
    font-weight: 400;
    color: #2BADE2;
    font-size: 48px;
	line-height: 48px;
}

.header-section-holder .header-section .content p.header-content {
    margin: 0;
    padding: 0;
    padding-top: 14px;
    color: #ffffff;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.1px;
}


/* Product */

.header-section-holder.product .header-section .header-content-image {
    background: url(../resources/images/page-title/page-title-product-services.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    height: 800px;
    width: 176%;
    margin-top: -20px;
    z-index: 1;
    position: absolute;
}


/* Why harbortouch */

.header-section-holder.why-harbortouch {
    background-color: #f9fafd;
}

.header-section-holder.why-harbortouch .header-section .header-content-image {
    background: url(../resources/images/home-spidometer.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    height: 800px;
    width: 100%;
    margin-top: 100px;
    z-index: 1;
    position: absolute;
}


/* Unmatched revenue */

.header-section-holder.revenue {
    background-color: #f9fafd;
}

.header-section-holder.revenue .header-section .header-content-image {
    background: url(../resources/images/page-title/page-title-unmatched-revenue.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    height: 400px;
    width: 200%;
    margin-top: 35px;
    z-index: 1;
    position: absolute;
}


/* Sales */

.header-section-holder.sales .header-section .header-content-image {
    background: url(../resources/images/page-title/page-title-sales-tools-trainings.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    height: 450px;
    width: 176%;
    margin-top: -20px;
    z-index: 1;
    position: absolute;
}

/* Integrated Payments Programs */

.header-section-holder.integrated-payments-programs .header-section .header-content-image {
    background: url(../resources/images/page-title/page-title-integrated-payments-programs.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    height: 450px;
    width: 176%;
    margin-top: -20px;
    z-index: 1;
    position: absolute;
}

/* Contact */

.header-section-holder.contact .header-section .header-content-image {
    background: url(../resources/images/page-title/page-title-get-started.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    height: 800px;
    width: 126%;
    margin-top: 50px;
    z-index: 1;
    position: absolute;
}


/* WHY HARBORTOUCH */

.why-harbortouch h2 {
    color: #26ACE2;
    font-weight: 400;
    font-size: 24px;
    letter-spacing: 0.170em;
    line-height: 24px;
    text-align: center;
}

.why-feature-item {
    -webkit-box-shadow: 0px 0px 20px 0px rgba(145, 183, 220, 0.25);
    -moz-box-shadow: 0px 0px 20px 0px rgba(145, 183, 220, 0.25);
    box-shadow: 0px 0px 20px 0px rgba(145, 183, 220, 0.25);
    width: 100%;
}

.why-feature-item p.number {
    color: #26ace2;
    font-weight: 600;
    font-size: 40px;
    display: inline-block;
    padding: 0;
    margin: 0;
    padding-top: 30px;
}

.why-feature-item p.number span {
    color: #0174a4;
    font-weight: 600;
    font-size: 18px;
    display: inline-block;
    padding: 0;
    margin: 0;
}

.why-feature-item p.title {
    color: #0174a4;
    font-size: 18px;
    display: block;
    padding: 0;
    margin: 0;
    /* padding-top: 10px; */
    padding-bottom: 26px;
}

.why-sub-text {
    color: #3d3d3d;
    font-size: 18px;
    padding: 0;
    margin: 0;
    margin-top: 28px;
}

.why-sub-text span {
    font-weight: 600;
}

.why-partners-logos img {
    margin: 40px 0 8px 0;
}

.why-info-item {
    -webkit-box-shadow: 0px 0px 20px 0px rgba(145, 183, 220, 0.25);
    -moz-box-shadow: 0px 0px 20px 0px rgba(145, 183, 220, 0.25);
    box-shadow: 0px 0px 20px 0px rgba(145, 183, 220, 0.25);
    width: 100%;
    border-radius: 5px;
}

.why-info-item-background {
    height: 200px;
    width: 100%;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.why-info-item-title {
    margin-top: -35px;
    background: #ffffff;
    border-radius: 0px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    margin-left: 16px;
    margin-right: 16px;
}

.why-info-item-title p {
    text-align: center;
    color: #0174a4;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    padding: 14px 0 12px 0;
}

.why-info-item-title .line {
    width: 41px;
    height: 2px;
    background: #26ace2;
    margin: 0 auto;
}

.why-info-item-content {
    margin: 12px 20px 12px 20px;
    min-height: 420px;
    display: block;
}

.why-info-item-content p {
    font-size: 16px;
    color: #3d3d3d;
    text-align: center;
}

.why-banner {
    background: url(../resources/images/why-harbortouch-banner/banner-background.jpg) no-repeat bottom center;
    background-size: contain;
    width: 100%;
    height: 300px;
}

.why-banner .why-left-device img {
    width: 100%;
    position: absolute;
    margin-left: 60px;
}

.why-banner .why-right-device img {
    width: 100%;
    position: absolute;
    margin-left: -60px;
}

.why-banner .why-center {
    margin-top: 80px;
    text-align: center;
}

.why-banner .why-center .sub-title {
    color: #0174A4;
	font-size: 30px;
	font-weight: 400;
}

.why-banner .why-center .title {
    color: #000000;
    font-size: 48px;
    line-height: 48px;
    font-weight: 400;
}

.why-banner .why-center a.why-button {
    display: block;
    width: 300px;
    margin: 0 auto;
    text-align: center;
    background-color: #26ace2;
    box-shadow: 0 5px 10px 0 rgba(38, 172, 226, .2);
    border-radius: 5px;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    padding: 15px 0px;
    margin-top: 45px;
    border: 1px solid #26ace2;
}

.why-banner .why-center a.why-button:hover {
    background-color: #ffffff;
    color: #26ace2;
}

footer {
    padding-top: 20px !important;
    background: #000 !important;
}

footer .footer-logo {
    text-align: center;
    margin-bottom: 18px;
}

footer .footer-logo img {
    max-width: 50%;
    width: 62px;
    height: 60px;
}

footer .footer-content {
    text-align: center;
}

footer .footer-content .footer-text {
    color: #ffffff;
    font-size: 14px;
    line-height: 14px;
    padding: 0;
    margin: 0;
    padding-bottom: 10px;
}

footer .footer-content .footer-text a {
    color: #ffffff;
}

footer .footer-content .footer-text span {
    color: #8d8d8d;
    font-weight: 600;
}

footer .footer-content .footer-social-icon {
    display: inline-block;
}

footer .footer-content .footer-social-icon img {
    width: 33px;
    height: auto;
	transition: all .2s ease-in-out;
	margin-bottom: 10px;
}

footer .footer-content .footer-social-icon img:hover {
    transform: scale(1.2);
}

footer .footer-line {
    background: #8d8d8d;
    width: 100%;
    height: 1px;
    margin: 13px 0;
}

footer .footer-content .footer-bottom-text {
    font-size: 12px;
    color: #8d8d8d;
    line-height: 18px;
}


/* Unmached revenue */

.unre-box {
    width: 100%;
    box-shadow: 0 0 20px 0px rgba(145, 183, 220, 0.25);
    background: #ffffff;
    border-radius: 5px;
    padding: 15px 30px;
}

.unre-box .unre-icon {
    width: 101px;
    margin: 0 auto;
    margin-top: -74px;
}

.unre-box .unre-icon img {
    width: 100%;
}

.unre-box .unre-title {
    font-weight: 600;
    font-size: 30px;
    color: #0174a4;
    text-align: center;
}

.unre-box .unre-title span {
    font-size: 48px;
    color: #26ace2;
}

.unre-box .unre-content {
    font-size: 24px;
    color: #3d3d3d;
    line-height: 32px;
    letter-spacing: -0.4px;
    text-align: center;
    padding-top: 10px;
}

.unre-box .unre-content span {
    font-weight: 600;
}

.unre-box .unre-after-content {
    font-size: 30px;
    color: #26ace2;
    font-weight: 600;
    text-align: center;
    padding-top: 18px;
    padding-bottom: 15px;
}

h2.unre-heading2 {
    color: #000;
    font-size: 36px;
    font-weight: 400;
    text-align: center;
    margin-top: 70px;
	margin-bottom: 70px;
}

.unre-info-item {
    -webkit-box-shadow: 0px 0px 20px 0px rgba(145, 183, 220, 0.25);
    -moz-box-shadow: 0px 0px 20px 0px rgba(145, 183, 220, 0.25);
    box-shadow: 0px 0px 20px 0px rgba(145, 183, 220, 0.25);
    width: 100%;
	border-radius: 5px;
}
.unpa-box .unre-info-item {
	background: #ffffff;
}

.unre-info-item-background {
    height: 145px;
    width: 100%;
    background: url(../resources/images/number-box-background.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.unre-info-item-background .unre-info-item-upto {
    font-size: 18px;
    line-height: 18px;
    color: #0174a4;
    /*padding: 20px 0 6px 0;*/
    padding: 13px 0 3px 0;
    margin: 0;
    text-align: center;
}

.unre-info-item-background .unre-info-item-price {
    font-size: 48px;
    line-height: 48px;
    color: #26ace2;
    font-weight: 600;
    padding: 0;
    padding-bottom: 3px;
    margin: 0;
    text-align: center;
}

.unre-info-item-background .unre-info-item-per {
    font-size: 18px;
    line-height: 18px;
    color: #0174a4;
    padding: 0;
    margin: 0;
    text-align: center;
}

.unre-info-item-title {
    margin-top: -35px;
    background: #ffffff;
    border-radius: 0px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    margin-left: 16px;
    margin-right: 16px;
}

.unre-info-item-title p {
    text-align: center;
    color: #0174a4;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    padding: 14px 0 12px 0;
}

.unre-info-item-title .line {
    width: 41px;
    height: 2px;
    background: #26ace2;
    margin: 0 auto;
}

.unre-info-item-content {
    margin: 12px 18px 12px 18px;
    min-height: 250px;
    display: block;
}

.unre-info-item-content p {
    font-size: 16px;
    color: #3d3d3d;
    text-align: center;
}

.unre-info-item-content p span {
    font-weight: 600;
}

.unre-reward-program {
    background: url(../resources/images/reward-program-exciting-contests.png);
    background-repeat: no-repeat;
    background-position: left center; // background-size: auto;
    background-size: 50%;
    background-color: #f9fafd;
    margin-top: 73px;
}

.unre-reward-program .image-on-mobile-screen {
    display: none;
}

.unre-reward-program h2 {
    font-size: 30px;
    color: #26ace2;
    font-weight: 600;
    padding-top: 90px;
    /*padding-bottom: 34px;*/
}

.unre-reward-program p {
    font-size: 16px;
    color: #3d3d3d;
    margin-bottom: 37px;
}

.unre-reward-program p span {
    font-weight: 600;
}

.unre-reward-program p.second {
    margin-bottom: 90px;
}

.stt-subnavigation-top {
    margin-top: 40px;
}

.stt-subnavigation.fixed,
#menu-center-fixed {
    position: fixed;
    width: 100%;
    background: #fafbfd;
    z-index: 99999999;
    top: 105px;
}

.stt-subnavigation .col-md-2.no-padding {
    width: 14.28500000% !important;
}

.stt-subnavigation .button-holder {
    text-align: center;
}

.stt-subnavigation .button-holder a {
    font-size: 16px;
    color: #3d3d3d;
    display: block;
    border-bottom: 2px solid #f8f9fd;
    padding-bottom: 17px;
    padding-top: 10px;
    display: block;
}

.stt-subnavigation .button-holder a:hover,
.stt-subnavigation .button-holder a.active {
    color: #2bade2;
    font-weight: 600;
    border-bottom: 4px solid #2bade2;
    padding-bottom: 15px;
}

.stt-section {
    margin: 70px 0;
}

.stt-section img {
	max-width: 100%;
	&.logo {
		max-width: 170px;
	}
}

.stt-section h3.stt-title {
    font-size: 30px;
    color: #26ace2;
    font-weight: 600;
    margin-top: 21px;
	margin-bottom: 21px;
	line-height: 32px;
}

.stt-section p.stt-description {
    font-size: 16px;
    color: #3d3d3d;
    text-align: justify;
}

.stt-section ul {
	list-style: none;
	padding-left: 15px;
    li {
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.5;
		letter-spacing: normal;
		text-align: justify;
		color: #3d3d3d;
		&:before {
			content: "\2022";
			color: #26ace2;
			font-weight: bold;
			display: inline-block;
			font-size: 20px;
			width: 15px;
			margin-left: -15px;
		}
	}
}

#harbortouch-university h3 {
    margin-top: 21px;
}

#harbortouch-online h3 {
    margin-top: 120px;
}

#free-pos-leads h3 {
    margin-top: 100px;
}

#pos-sales-center-ipad-app h3 {
    margin-top: 120px;
}

#harbortouch-demo-kiosk h3 {
    margin-top: 75px;
}

#marketing-materials h3 {
    margin-top: 140px;
}

#your-own-harbortouch-website h3 {
    margin-top: 140px;
}


/* Contacts */

h2.contacts {
    font-size: 30px;
    color: #26ace2;
    font-weight: 600;
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
}

.contact-person-box {
    width: 100%;
    box-shadow: 0 0 20px 0 rgba(145, 183, 220, .25);
    background: #fff;
    border-radius: 5px;
    padding: 30px 30px;
    margin-top: 69px;
}

.contact-person-box.first {
    margin-top: 47px;
}

.contact-person-box .contact-person-photo {
    width: 90px;
    margin: 0 auto;
    margin-top: -75px;
    margin-right: 3px;
    border-radius: 50%;
    border: 4px solid rgba(1, 116, 164, 0.15);
    overflow: hidden;
}

.contact-person-box .contact-person-photo img {
    width: 100%;
}

.contact-person-box .contact-person-name {
    font-weight: 600;
    font-size: 18px;
    color: #0174a4;
    padding-bottom: 0;
    margin-bottom: 0;
}

.contact-person-box .contact-person-line {
    width: 40px;
    height: 2px;
    background: #26ace2;
    margin: 10px 0;
    display: block;
}

.contact-person-box .contact-person-description {
    font-size: 16px;
    color: #3d3d3d;
    padding-top: 0px;
    padding-bottom: 20px;
    margin-bottom: 0;
}

.contact-person-box .contact-person-phone,
.contact-person-box .contact-person-email {
    font-size: 16px;
    color: #3d3d3d;
    margin: 0;
    padding: 0;
    padding-bottom: 10px;
}

.contact-person-box .contact-person-phone span.contact-bold,
.contact-person-box .contact-person-email span.contact-bold {
    font-size: 16px;
    color: #3d3d3d;
    font-weight: 600;
}

.contact-person-box .contact-person-phone .contact-icon,
.contact-person-box .contact-person-email .contact-icon {
    width: 20px;
    height: 16px;
    display: inline-block;
    margin-right: 14px;
}

.contact-person-box .contact-person-phone .contact-icon svg path,
.contact-person-box .contact-person-email .contact-icon svg path {
    fill: #24c1f1;
}

.contact-social {
    margin: 30px 0;
}

.contact-social p,
.contact-social a {
    display: inline-block;
}

.contact-social p {
    font-size: 30px;
    color: #26ace2;
    font-weight: 600;
    padding: 0;
    margin: 0;
    padding-right: 10px;
    line-height: 32px;
}

.contact-social a {
    width: 34px;
    height: 34px;
}

.contact-social a img {
    width: 100%;
    margin-top: -14px;
    transition: all .2s ease-in-out;
}

.contact-social a img:hover {
    transform: scale(1.2);
}

.sky-form a {
    color: #2da5da;
}

.sky-form .label {
    display: block;
    margin-bottom: 6px;
    line-height: 19px;
    font-weight: 400;
}

.sky-form .label.col {
    margin: 0;
    padding-top: 10px;
}

.sky-form .note {
    margin-top: 6px;
    padding: 0 1px;
    font-size: 11px;
    line-height: 15px;
    color: #999;
}

.sky-form .input,
.sky-form .select,
.sky-form .textarea,
.sky-form .radio,
.sky-form .checkbox,
.sky-form .toggle,
.sky-form .button {
    position: relative;
    display: block;
}

.sky-form .input input,
.sky-form .select select,
.sky-form .textarea textarea {
    display: block;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    width: 100%;
    height: 39px;
    padding: 6px 10px;
    outline: none;
    border-width: 2px;
    border-style: solid;
    border-radius: 0;
    background: #fff;
    font: 15px/23px 'Roboto', Helvetica, Arial, sans-serif;
    color: #404040;
    -moz-appearance: none;
    -webkit-appearance: none;
}

.sky-form .progress {
    float: right;
    margin-top: 10px;
    line-height: 39px;
    color: #232323;
}

.sky-form button::-moz-focus-inner {
    padding: 0;
    border: 0;
}


/**/


/* captcha inputs */


/**/

.sky-form .input-captcha img {
    position: absolute;
    top: 2px;
    right: 2px;
    border-left: 1px solid #e5e5e5;
}


/**/


/* file inputs */


/**/

.sky-form .input-file .button {
    position: absolute;
    top: 4px;
    right: 4px;
    float: none;
    height: 31px;
    margin: 0;
    padding: 0 20px;
    font-size: 13px;
    line-height: 31px;
}

.sky-form .input-file .button:hover {
    box-shadow: none;
}

.sky-form .input-file .button input {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    font-size: 30px;
    cursor: pointer;
    opacity: 0;
}


/**/


/* selects */


/**/

.sky-form .select i {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 28px;
    height: 35px;
    background: #fff;
    pointer-events: none;
}

.sky-form .select i:after,
.sky-form .select i:before {
    content: '';
    position: absolute;
    right: 10px;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.sky-form .select i:after {
    bottom: 12px;
    border-top: 4px solid #404040;
}

.sky-form .select i:before {
    top: 12px;
    border-bottom: 4px solid #404040;
}

.sky-form .select select {
    padding-right: 28px;
}

.sky-form .select-multiple select {
    height: auto;
}


/**/


/* textareas */


/**/

.sky-form .textarea textarea {
    height: auto;
    resize: none;
}

.sky-form .textarea-resizable textarea {
    resize: vertical;
}

.sky-form .textarea-expandable textarea {
    height: 39px;
}

.sky-form .textarea-expandable textarea:focus {
    height: auto;
}


/**/


/* radios and checkboxes */


/**/

.sky-form .radio,
.sky-form .checkbox {
    margin-bottom: 4px;
    padding-left: 27px;
    font-size: 15px;
    line-height: 27px;
    color: #404040;
    cursor: pointer;
}

.sky-form .radio:last-child,
.sky-form .checkbox:last-child {
    margin-bottom: 0;
}

.sky-form .radio input,
.sky-form .checkbox input {
    position: absolute;
    left: -9999px;
}

.sky-form .radio i,
.sky-form .checkbox i {
    position: absolute;
    top: 5px;
    left: 0;
    display: block;
    width: 13px;
    height: 13px;
    outline: none;
    border-width: 2px;
    border-style: solid;
    background: #fff;
}

.sky-form .radio i {
    border-radius: 50%;
}

.sky-form .radio input+i:after,
.sky-form .checkbox input+i:after {
    position: absolute;
    opacity: 0;
    transition: opacity 0.1s;
    -ms-transition: opacity 0.1s;
    -moz-transition: opacity 0.1s;
    -webkit-transition: opacity 0.1s;
}

.sky-form .radio input+i:after {
    content: '';
    top: 4px;
    left: 4px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
}

.sky-form .checkbox input+i:after {
    content: '\f00c';
    top: -1px;
    left: -1px;
    width: 15px;
    height: 15px;
    font: normal 12px/16px FontAwesome;
    text-align: center;
}

.sky-form .radio input:checked+i:after,
.sky-form .checkbox input:checked+i:after {
    opacity: 1;
}

.sky-form .inline-group {
    margin: 0 -30px -4px 0;
}

.sky-form .inline-group:after {
    content: '';
    display: table;
    clear: both;
}

.sky-form .inline-group .radio,
.sky-form .inline-group .checkbox {
    float: left;
    margin-right: 30px;
}

.sky-form .inline-group .radio:last-child,
.sky-form .inline-group .checkbox:last-child {
    margin-bottom: 4px;
}


/**/


/* toggles */


/**/

.sky-form .toggle {
    margin-bottom: 4px;
    padding-right: 61px;
    font-size: 15px;
    line-height: 27px;
    color: #404040;
    cursor: pointer;
}

.sky-form .toggle:last-child {
    margin-bottom: 0;
}

.sky-form .toggle input {
    position: absolute;
    left: -9999px;
}

.sky-form .toggle i {
    content: '';
    position: absolute;
    top: 4px;
    right: 0;
    display: block;
    width: 49px;
    height: 17px;
    border-width: 2px;
    border-style: solid;
    border-radius: 12px;
    background: #fff;
}

.sky-form .toggle i:after {
    content: 'OFF';
    position: absolute;
    top: 2px;
    right: 8px;
    left: 8px;
    font-style: normal;
    font-size: 9px;
    line-height: 13px;
    font-weight: 700;
    text-align: left;
    color: #5f5f5f;
}

.sky-form .toggle i:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 4px;
    right: 4px;
    display: block;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    opacity: 1;
    transition: right 0.2s;
    -ms-transition: right 0.2s;
    -moz-transition: right 0.2s;
    -webkit-transition: right 0.2s;
}

.sky-form .toggle input:checked+i:after {
    content: 'ON';
    text-align: right;
}

.sky-form .toggle input:checked+i:before {
    right: 36px;
}


/**/


/* ratings */


/**/

.sky-form .rating {
    margin-bottom: 4px;
    font-size: 15px;
    line-height: 27px;
    color: #404040;
}

.sky-form .rating:last-child {
    margin-bottom: 0;
}

.sky-form .rating input {
    position: absolute;
    left: -9999px;
}

.sky-form .rating label {
    display: block;
    float: right;
    height: 17px;
    margin-top: 5px;
    padding: 0 2px;
    font-size: 17px;
    line-height: 17px;
    cursor: pointer;
}


/**/


/* buttons */


/**/

.sky-form .button {
    float: right;
    height: 39px;
    overflow: hidden;
    margin: 10px 0 0 20px;
    padding: 0 25px;
    outline: none;
    border: 0;
    font: 300 15px/39px 'Roboto', Helvetica, Arial, sans-serif;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
}

.sky-form .button-uploading {
    position: relative;
    color: transparent;
    cursor: default;
}

.sky-form .button-uploading:after {
    content: 'Uploading...';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    animation: blink 1s linear infinite;
    -o-animation: blink 1s linear infinite;
    -ms-animation: blink 1s linear infinite;
    -moz-animation: blink 1s linear infinite;
    -webkit-animation: blink 1s linear infinite;
}

@keyframes blink {
    0% {
        opacity: 1
    }
    50% {
        opacity: 0.3
    }
    100% {
        opacity: 1
    }
}

@-o-keyframes blink {
    0% {
        opacity: 1
    }
    50% {
        opacity: 0.3
    }
    100% {
        opacity: 1
    }
}

@-ms-keyframes blink {
    0% {
        opacity: 1
    }
    50% {
        opacity: 0.3
    }
    100% {
        opacity: 1
    }
}

@-moz-keyframes blink {
    0% {
        opacity: 1
    }
    50% {
        opacity: 0.3
    }
    100% {
        opacity: 1
    }
}

@-webkit-keyframes blink {
    0% {
        opacity: 1
    }
    50% {
        opacity: 0.3
    }
    100% {
        opacity: 1
    }
}


/**/


/* icons */


/**/

.sky-form .icon-append,
.sky-form .icon-prepend {
    position: absolute;
    top: 5px;
    width: 29px;
    height: 29px;
    font-size: 15px;
    line-height: 29px;
    text-align: center;
}

.sky-form .icon-append {
    right: 5px;
    padding-left: 3px;
    border-left-width: 1px;
    border-left-style: solid;
}

.sky-form .icon-prepend {
    left: 5px;
    padding-right: 3px;
    border-right-width: 1px;
    border-right-style: solid;
}

.sky-form .input .icon-prepend+input,
.sky-form .textarea .icon-prepend+textarea {
    padding-left: 46px;
}

.sky-form .input .icon-append+input,
.sky-form .textarea .icon-append+textarea {
    padding-right: 46px;
}

.sky-form .input .icon-prepend+.icon-append+input,
.sky-form .textarea .icon-prepend+.icon-append+textarea {
    padding-left: 46px;
}


/**/


/* grid */


/**/

.sky-form .row {
    margin: 0 -15px;
}

.sky-form .row:after {
    content: '';
    display: table;
    clear: both;
}

.sky-form .col {
    float: left;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.sky-form .col-1 {
    width: 8.33%;
}

.sky-form .col-2 {
    width: 16.66%;
}

.sky-form .col-3 {
    width: 25%;
}

.sky-form .col-4 {
    width: 33.33%;
}

.sky-form .col-5 {
    width: 41.66%;
}

.sky-form .col-6 {
    width: 50%;
}

.sky-form .col-7 {
    width: 58.33%;
}

.sky-form .col-8 {
    width: 66.67%;
}

.sky-form .col-9 {
    width: 75%;
}

.sky-form .col-10 {
    width: 83.33%;
}

.sky-form .col-11 {
    width: 91.66%;
}

@media screen and (max-width: 600px) {
    .sky-form .col {
        float: none;
        width: 100%;
    }
}


/**/


/* tooltips */


/**/

.sky-form .tooltip {
    position: absolute;
    z-index: 1;
    left: -9999px;
    padding: 2px 8px 3px;
    font-size: 11px;
    line-height: 16px;
    font-weight: 400;
    background: rgba(0, 0, 0, 0.9);
    color: #fff;
    opacity: 0;
    transition: margin 0.3s, opacity 0.3s;
    -ms-transition: margin 0.3s, opacity 0.3s;
    -moz-transition: margin 0.3s, opacity 0.3s;
    -webkit-transition: margin 0.3s, opacity 0.3s;
}

.sky-form .tooltip:after {
    content: '';
    position: absolute;
}

.sky-form .input input:focus+.tooltip,
.sky-form .textarea textarea:focus+.tooltip {
    opacity: 1;
}

.sky-form .tooltip-top-right {
    bottom: 100%;
    margin-bottom: 15px;
}

.sky-form .tooltip-top-right:after {
    top: 100%;
    right: 16px;
    border-top: 4px solid rgba(0, 0, 0, 0.9);
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.sky-form .input input:focus+.tooltip-top-right,
.sky-form .textarea textarea:focus+.tooltip-top-right {
    right: 0;
    left: auto;
    margin-bottom: 5px;
}

.sky-form .tooltip-top-left {
    bottom: 100%;
    margin-bottom: 15px;
}

.sky-form .tooltip-top-left:after {
    top: 100%;
    left: 16px;
    border-top: 4px solid rgba(0, 0, 0, 0.9);
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.sky-form .input input:focus+.tooltip-top-left,
.sky-form .textarea textarea:focus+.tooltip-top-left {
    right: auto;
    left: 0;
    margin-bottom: 5px;
}

.sky-form .tooltip-right {
    top: 9px;
    white-space: nowrap;
    margin-left: 15px;
}

.sky-form .tooltip-right:after {
    top: 6px;
    right: 100%;
    border-top: 4px solid transparent;
    border-right: 4px solid rgba(0, 0, 0, 0.9);
    border-bottom: 4px solid transparent;
}

.sky-form .input input:focus+.tooltip-right,
.sky-form .textarea textarea:focus+.tooltip-right {
    left: 100%;
    margin-left: 5px;
}

.sky-form .tooltip-left {
    top: 9px;
    white-space: nowrap;
    margin-right: 15px;
}

.sky-form .tooltip-left:after {
    top: 6px;
    left: 100%;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 4px solid rgba(0, 0, 0, 0.9);
}

.sky-form .input input:focus+.tooltip-left,
.sky-form .textarea textarea:focus+.tooltip-left {
    right: 100%;
    left: auto;
    margin-right: 5px;
}

.sky-form .tooltip-bottom-right {
    top: 100%;
    margin-top: 15px;
}

.sky-form .tooltip-bottom-right:after {
    bottom: 100%;
    right: 16px;
    border-right: 4px solid transparent;
    border-bottom: 4px solid rgba(0, 0, 0, 0.9);
    border-left: 4px solid transparent;
}

.sky-form .input input:focus+.tooltip-bottom-right,
.sky-form .textarea textarea:focus+.tooltip-bottom-right {
    right: 0;
    left: auto;
    margin-top: 5px;
}

.sky-form .tooltip-bottom-left {
    top: 100%;
    margin-top: 15px;
}

.sky-form .tooltip-bottom-left:after {
    bottom: 100%;
    left: 16px;
    border-right: 4px solid transparent;
    border-bottom: 4px solid rgba(0, 0, 0, 0.9);
    border-left: 4px solid transparent;
}

.sky-form .input input:focus+.tooltip-bottom-left,
.sky-form .textarea textarea:focus+.tooltip-bottom-left {
    right: auto;
    left: 0;
    margin-top: 5px;
}


/**/


/* normal state */


/**/

.sky-form .input input,
.sky-form .select select,
.sky-form .textarea textarea,
.sky-form .radio i,
.sky-form .checkbox i,
.sky-form .toggle i,
.sky-form .icon-append,
.sky-form .icon-prepend {
    border-color: #e5e5e5;
    transition: border-color 0.3s;
    -ms-transition: border-color 0.3s;
    -moz-transition: border-color 0.3s;
    -webkit-transition: border-color 0.3s;
}

.sky-form .toggle i:before {
    background-color: #2da5da;
}

.sky-form .rating label {
    color: #ccc;
    transition: color 0.3s;
    -ms-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -webkit-transition: color 0.3s;
}

.sky-form .button {
    background-color: #2da5da;
    opacity: 0.8;
    transition: opacity 0.2s;
    -ms-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    -webkit-transition: opacity 0.2s;
}

.sky-form .button.button-secondary {
    background-color: #b3b3b3;
}

.sky-form .icon-append,
.sky-form .icon-prepend {
    color: #ccc;
}


/**/


/* hover state */


/**/

.sky-form .input:hover input,
.sky-form .select:hover select,
.sky-form .textarea:hover textarea,
.sky-form .radio:hover i,
.sky-form .checkbox:hover i,
.sky-form .toggle:hover i,
.sky-form .ui-slider-handle:hover {
    border-color: #8dc9e5;
}

.sky-form .rating input+label:hover,
.sky-form .rating input+label:hover~label {
    color: #2da5da;
}

.sky-form .button:hover {
    opacity: 1;
}


/**/


/* focus state */


/**/

.sky-form .input input:focus,
.sky-form .select select:focus,
.sky-form .textarea textarea:focus,
.sky-form .radio input:focus+i,
.sky-form .checkbox input:focus+i,
.sky-form .toggle input:focus+i {
    border-color: #2da5da;
}


/**/


/* checked state */


/**/

.sky-form .radio input+i:after {
    background-color: #2da5da;
}

.sky-form .checkbox input+i:after {
    color: #2da5da;
}

.sky-form .radio input:checked+i,
.sky-form .checkbox input:checked+i,
.sky-form .toggle input:checked+i {
    border-color: #2da5da;
}

.sky-form .rating input:checked~label {
    color: #2da5da;
}


/**/


/* error state */


/**/

.sky-form .state-error input,
.sky-form .state-error select,
.sky-form .state-error select+i,
.sky-form .state-error textarea,
.sky-form .radio.state-error i,
.sky-form .checkbox.state-error i,
.sky-form .toggle.state-error i,
.sky-form .toggle.state-error input:checked+i {
    background: #fff0f0;
}

.sky-form .state-error+em {
    display: block;
    margin-top: 6px;
    padding: 0 1px;
    font-style: normal;
    font-size: 11px;
    line-height: 15px;
    color: #ee9393;
}

.sky-form .rating.state-error+em {
    margin-top: -4px;
    margin-bottom: 4px;
}


/**/


/* success state */


/**/

.sky-form .state-success input,
.sky-form .state-success select,
.sky-form .state-success select+i,
.sky-form .state-success textarea,
.sky-form .radio.state-success i,
.sky-form .checkbox.state-success i,
.sky-form .toggle.state-success i,
.sky-form .toggle.state-success input:checked+i {
    background: #f0fff0;
}

.sky-form .state-success+em {
    display: block;
    margin-top: 6px;
    padding: 0 1px;
    font-style: normal;
    font-size: 11px;
    line-height: 15px;
    color: #ee9393;
}

.sky-form .note-success {
    color: #6fb679;
}


/**/


/* disabled state */


/**/

.sky-form .input.state-disabled input,
.sky-form .select.state-disabled,
.sky-form .textarea.state-disabled,
.sky-form .radio.state-disabled,
.sky-form .checkbox.state-disabled,
.sky-form .toggle.state-disabled,
.sky-form .button.state-disabled {
    cursor: default;
    opacity: 0.5;
}

.sky-form .input.state-disabled:hover input,
.sky-form .select.state-disabled:hover select,
.sky-form .textarea.state-disabled:hover textarea,
.sky-form .radio.state-disabled:hover i,
.sky-form .checkbox.state-disabled:hover i,
.sky-form .toggle.state-disabled:hover i {
    border-color: #e5e5e5;
}


/**/


/* submited state */


/**/

.sky-form .message {
    display: none;
    color: #6fb679;
}

.sky-form .message i {
    display: block;
    margin: 0 auto 20px;
    width: 81px;
    height: 81px;
    border: 1px solid #6fb679;
    border-radius: 50%;
    font-size: 30px;
    line-height: 81px;
}

.sky-form.submited fieldset,
.sky-form.submited footer {
    display: none;
}

.sky-form.submited .message {
    display: block;
    padding: 25px 30px;
    background: rgba(255, 255, 255, .9);
    font: 300 18px/27px 'Roboto', Helvetica, Arial, sans-serif;
    text-align: center;
}


/**/


/* datepicker */


/**/

.ui-datepicker {
    display: none;
    padding: 10px 12px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 0 10px rgba(0, 0, 0, .3);
    font: 13px/1.55 'Roboto', Helvetica, Arial, sans-serif;
    text-align: center;
    color: #666;
}

.ui-datepicker a {
    color: #404040;
}

.ui-datepicker-header {
    position: relative;
    margin: -10px -12px 10px;
    padding: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    font-size: 15px;
    line-height: 27px;
}

.ui-datepicker-prev,
.ui-datepicker-next {
    position: absolute;
    top: 0;
    display: block;
    width: 47px;
    height: 47px;
    font-size: 15px;
    line-height: 47px;
    text-decoration: none;
    cursor: pointer;
}

.ui-datepicker-prev {
    left: 0;
}

.ui-datepicker-next {
    right: 0;
}

.ui-datepicker-calendar {
    border-collapse: collapse;
    font-size: 13px;
    line-height: 27px;
}

.ui-datepicker-calendar th {
    color: #999;
}

.ui-datepicker-calendar a,
.ui-datepicker-calendar span {
    display: block;
    width: 31px;
    margin: auto;
    text-decoration: none;
    color: #404040;
}

.ui-datepicker-calendar a:hover {
    background: rgba(0, 0, 0, .05);
}

.ui-datepicker-calendar span {
    color: #bfbfbf;
}

.ui-datepicker-today a {
    font-weight: 700;
}

.ui-datepicker-calendar .ui-state-active {
    background: rgba(0, 0, 0, .05);
    cursor: default;
}

.ui-datepicker-inline {
    border: 2px solid #e5e5e5;
    background: #fff;
    box-shadow: none;
}

.ui-datepicker-inline .ui-datepicker-header {
    line-height: 47px;
}

.ui-datepicker-inline .ui-datepicker-calendar {
    width: 100%;
}


/**/


/* slider */


/**/

.sky-form .ui-slider {
    position: relative;
    height: 3px;
    border: 2px solid #e5e5e5;
    background: #fff;
    margin: 12px 6px 26px;
}

.sky-form .ui-slider-handle {
    position: absolute;
    width: 15px;
    height: 15px;
    margin: -8px 0 0 -8px;
    border: 2px solid #e5e5e5;
    outline: none;
    background: #fff;
    transition: border-color 0.3s;
    -ms-transition: border-color 0.3s;
    -moz-transition: border-color 0.3s;
    -webkit-transition: border-color 0.3s;
}


/**/


/* modal */


/**/

.sky-form-modal {
    position: fixed;
    z-index: 1;
    display: none;
    width: 400px;
}

.sky-form-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
}


/**/


/* bootstrap compatibility */


/**/

.sky-form *,
.sky-form *:after,
.sky-form *:before {
    margin: 0;
    padding: 0;
    box-sizing: content-box;
    -moz-box-sizing: content-box;
}

.sky-form .label {
    border-radius: 0;
    font-size: 100%;
    text-align: left;
    white-space: normal;
    color: inherit;
}

.sky-form .radio,
.sky-form .checkbox {
    font-weight: 400;
}

.sky-form .radio+.radio,
.sky-form .checkbox+.checkbox {
    margin-top: 0;
}

.get-started-today-form .label {
    font-size: 16px !important;
    color: #3d3d3d !important;
    font-weight: 600;
    padding-bottom: 3px;
}

.get-started-today-form .input input,
.get-started-today-form .select select,
.get-started-today-form .textarea textarea {
    border-color: #c0c2c6;
    border-radius: 5px !important;
    font-size: 16px !important;
    color: #c0c2c6 !important;
    background: #fff !important;
    transition: color 0.3s;
    -webkit-transition: color 0.3s;
    font-family: "Proxima Nova", sans-serif !important;
}

.get-started-today-form .input input.error,
.get-started-today-form .select select.error,
.get-started-today-form .textarea textarea.error {
    border-color: #f23931;
}

.get-started-today-form .input input.correct,
.get-started-today-form .select select.correct,
.get-started-today-form .textarea textarea.correct {
    border-color: #4ecb53;
}

.get-started-today-form .input input,
.get-started-today-form .select select {
    height: 50px !important;
    padding-left: 50px !important;
}

.get-started-today-form .textarea textarea {
    height: 278px !important;
    padding: 20px !important;
}

.get-started-today-form .select select {
    background: url(../resources/images/arrow-down.png) !important;
    background-repeat: no-repeat !important;
    background-position: right 25px center !important;
    background-size: auto !important;
}

.get-started-today-form select:focus,
.get-started-today-form select:hover,
.get-started-today-form select:active {
    background: url(../resources/images/arrow-down-active.png) !important;
    background-repeat: no-repeat !important;
    background-position: right 25px center !important;
    background-size: auto !important;
}

.get-started-today-form input:focus,
.get-started-today-form select:focus,
.get-started-today-form textarea:focus,
.get-started-today-form input:hover,
.get-started-today-form select:hover,
.get-started-today-form textarea:hover,
.get-started-today-form input:active,
.get-started-today-form select:active,
.get-started-today-form textarea:active {
    border-color: #26ace2 !important;
    color: #26ace2 !important;
}

.get-started-today-form .icon-form {
    position: absolute;
    width: 25px;
    padding: 15px;
}

.get-started-today-form .icon-form svg path {
    transition: fill 0.3s;
    -webkit-transition: fill 0.3s;
}

.input:focus .icon-form svg path,
.select:focus .icon-form svg path,
.textarea:focus .icon-form svg path,
.input:hover .icon-form svg path,
.select:hover .icon-form svg path,
.textarea:hover .icon-form svg path,
.input:active .icon-form svg path,
.select:active .icon-form svg path,
.textarea:active .icon-form svg path {
    fill: #26ace2;
}

.get-started-today-form section {
    margin-bottom: 27px !important;
}

.contact-footer {
    width: 100%;
}

.contact-footer #button {
    display: block;
    width: 264px;
    margin: 0 auto;
    text-align: center;
    text-transform: uppercase;
    background-color: #26ace2;
    box-shadow: 0 5px 10px 0 rgba(38, 172, 226, .2);
    border-radius: 5px;
    border-color: #26ace2;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    padding: 5px 0;
    margin-top: 25px;
    display: block;
    margin: 0 auto;
}

.contact-footer #button:hover {
    background-color: #ffffff;
    color: #26ace2;
}

button#button {
    outline: none;
    height: 40px;
    text-align: center;
    width: 130px;
    border-radius: 40px;
    background: #fff;
    border: 2px solid #26ace2;
    color: #26ace2;
    text-shadow: 0;
    cursor: pointer;
    transition: all 0.25s ease;
    &:hover {
        color: #ffffff;
        background: #0174a4;
    }
    &:active {
        letter-spacing: 2px;
    }
    &:after {
        content: "Send Message";
    }
}

button#button.onclic {
    width: 40px;
    height: 40px;
    padding: 0;
    background: #fff;
    border-color: #bbbbbb;
    border-width: 3px;
    font-size: 0;
    border-left-color: #4ecb53;
    animation: rotating 2s 0.25s linear infinite;
    border-radius: 50%;
    &:after {
        content: "";
    }
    &:hover {
        color: #26ace2;
        background: #ffffff;
    }
}

button#button.validate {
    font-size: 13px;
    color: white;
    background: #4ecb53;
    border-color: #4ecb53;
    &:after {
        font-family: 'Font Awesome 5 Free' !important;
        font-weight: 900;
        content: "\f00c";
    }
}

button#button.validateError {
    font-size: 13px;
    color: white;
    background: #f23931;
    border-color: #f23931;
    &:after {
        font-family: 'Font Awesome 5 Free' !important;
        font-weight: 900;
        content: "\f00d";
    }
}

@keyframes rotating {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


/* Home */

.skytab-selection {
	background-color: #F9FAFD;
	padding: 77px 0 88px 0;
	h2 {
		color: #26ACE2;
		font-size: 48px;
		font-weight: 400;
		letter-spacing: 0;
		line-height: 48px;
		text-align: center;
	}
	p {
		color: #3D3D3D;
		font-size: 24px;
		letter-spacing: 0;
		line-height: 32px;
		text-align: center;
	}
	.box {
		background-color: #FFFFFF;
		box-shadow: 0 0 20px 0px rgba(145, 183, 220, 0.25);
		border-radius: 5px;
		padding: 37px 40px;
		margin: 30px 15px;
		h3 {
			color: #26ACE2;
			font-size: 30px;
			font-weight: 600;
			letter-spacing: 0;
			line-height: 30px;
			text-align: center;
			margin-bottom: 30px;
			span {
				display: block;
				padding-top: 10px;
				color: #0174A4;
				font-size: 18px;
				font-weight: bold;
				letter-spacing: 0;
				line-height: 18px;
			}
		}
		img {
			max-width: 100%;
			max-height: 232px;
			margin: 0 auto;
			display: block;
			margin-bottom: 30px;
		}
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			li {
				color: #3D3D3D;
				font-weight: 400;
				font-size: 16px;
				letter-spacing: 0;
				line-height: 28px;
				padding-left: 45px;
				background-repeat: no-repeat;
				background-size: 28px;
				background-position: left top;
				background-image: url(../resources/images/skytab/feature-star-icon.svg);
				margin-bottom: 10px;
			}
		}
		a {
			display: block;
			width: 250px;
			height: 55px;
			margin: 0 auto;
			text-align: center;
			background-color: #26ace2;
			box-shadow: 0 5px 10px 0 rgba(38, 172, 226, .2);
			border-radius: 5px;
			color: #fff;
			font-weight: 600;
			font-size: 16px;
			padding: 15px 0;
			margin-top: 35px;
			border: 1px solid #26ace2;
		}
	}
}


/* Hotel Market section */

.hotel-market {
	margin: 70px 0;
	h2 {
		font-weight: 400;
		font-size: 36px;
		color: #000000;
		text-align: center;
		margin-bottom: 60px;
	}
	p {
		font-size: 18px;
		line-height: 32px;
		color: #3d3d3d;
		padding: 0;
		margin: 0;
		text-align: center;
		padding-bottom: 20px;
	}
	h4 {
		padding-top: 40px;
		color: #3d3d3d;
	}
	ul {
		li {
			font-size: 16px;
			line-height: 24px;
			letter-spacing: 0.5px;
			margin: 15px 0;
			list-style: none;
			&::before {
				content: ' ';
				background-image: url('/resources/images/skytab/feature-star-icon.svg');
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center center;
				margin: 11px 12px -6px -39px;
				width: 27px;
				height: 27px;
				display: inline-block;
			}
		}
	}
	img {
		max-width: 100%;
		padding-top: 20px;
	}
	p.bottom-text {
        font-size: 24px;
        text-align: center;
		padding-top: 60px;
	}
	a.learn-more {
		display: block;
		width: 250px;
		height: 55px;
		margin: 0 auto;
		text-align: center;
		background-color: #26ace2;
		box-shadow: 0 5px 10px 0 rgba(38, 172, 226, .2);
		border-radius: 5px;
		color: #fff;
		font-weight: 600;
		font-size: 16px;
		padding: 15px 0;
		margin-top: 35px;
		border: 1px solid #26ace2;
	}
	a.learn-more:hover {
		background-color: #ffffff;
		color: #26ace2;
	}
}

/* Integrated Payments section */

.integrated-payments {
	margin: 70px 0 100px 0;
	h2 {
		font-weight: 400;
		font-size: 36px;
		color: #000000;
		text-align: center;
		margin-bottom: 34px;
	}
	p {
		font-size: 18px;
		line-height: 32px;
		color: #3d3d3d;
		padding: 0;
		margin: 0;
		text-align: center;
		padding-bottom: 40px;
	}
	.box {
		width: 100%;
		box-shadow: 0 0 20px 0px rgba(145, 183, 220, 0.25);
		background: #ffffff;
		border-radius: 12px;
		padding: 0;
		min-height: 230px;
		margin-top: 20px;
		img {
			border-top-left-radius: 12px;
			border-top-right-radius: 12px;
		}
		.content {
			padding: 30px;
			p.title {
				font-size: 18px;
				font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: normal;
				text-align: center;
				color: #0174a4;
			}
			p.text {
				font-size: 16px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.5;
				letter-spacing: normal;
				text-align: left;
				color: #3d3d3d;
			}
			.link {
				text-align: center;
				a {
					font-weight: 600;
					font-size: 16px;
					color: #26ace2;
					span.blue-right-arrow {
						display: inline-block;
						width: 20px;
						height: 12px;
						background: url(../resources/images/blue-right-arrow.png);
						background-repeat: no-repeat;
						background-position: right center;
						background-size: auto;
					}
					&:hover span.blue-right-arrow {
						animation: shake 1.25s cubic-bezier(.36, .07, .19, .97) both;
						transform: translate3d(0, 0, 0);
						backface-visibility: hidden;
					}
					@keyframes shake {
						10%,
						90% {
							transform: translate3d(-1px, 0, 0);
						}
						20%,
						80% {
							transform: translate3d(2px, 0, 0);
						}
						30%,
						50%,
						70% {
							transform: translate3d(-4px, 0, 0);
						}
						40%,
						60% {
							transform: translate3d(4px, 0, 0);
						}
					}
				}
			}
		}
	}
}

.learn-more-box {
    padding-top: 130px;
	padding-bottom: 70px;
	background-color: #F9FAFD;
}

.learn-more-box .learnmore-box {
    width: 100%;
    box-shadow: 0 0 20px 0px rgba(145, 183, 220, 0.25);
    background: #ffffff;
    border-radius: 5px;
    padding: 15px 15px;
    min-height: 230px;
}

.learn-more-box .learnmore-box .learnmore-icon {
    width: 101px;
    margin: 0 auto;
    margin-top: -74px;
}

.learn-more-box .learnmore-box .learnmore-icon img {
    width: 100%;
}

.learn-more-box .learnmore-box .learnmore-title {
    font-weight: 600;
    font-size: 18px;
    letter-spacing: -0.4px;
    color: #0174a4;
    text-align: center;
    margin-top: 20px;
    text-transform: uppercase;
}

.learn-more-box .learnmore-box .learnmore-content {
    font-size: 16px;
    color: #3d3d3d;
    line-height: 24px;
    /* letter-spacing: -0.4px; */
    text-align: center;
    padding-top: 5px;
}

.learn-more-box .learnmore-box .learnmore-link {
    text-align: center;
}

.learn-more-box .learnmore-box .learnmore-link a {
    font-weight: 600;
    font-size: 16px;
    color: #26ace2;
}

.learn-more-box .learnmore-box .learnmore-link a span.blue-right-arrow {
    display: inline-block;
    width: 20px;
    height: 12px;
    background: url(../resources/images/blue-right-arrow.png);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: auto;
}

.learn-more-box .learnmore-box .learnmore-link a:hover span.blue-right-arrow {
    animation: shake 1.25s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

.free-pos-program {
    background: url(../resources/images/free-pos-program-background.png);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    padding: 68px 0 130px 0;
}

.free-pos-program h2 {
    font-weight: 400;
    font-size: 48px;
    color: #26ace2;
    text-align: center;
    margin-bottom: 60px;
}

.free-pos-program p {
    font-size: 24px;
    line-height: 32px;
    color: #ffffff;
    text-align: center;
}

.free-pos-program p span {
    font-weight: 600;
}

.free-pos-program .left-block {
    margin-top: 44px;
}

.free-pos-program .left-block .title {
    font-weight: 600;
    font-size: 24px;
    color: #ffffff;
    text-align: left;
}

.free-pos-program .left-block .title span {
    color: #26ace2;
}

.free-pos-program .left-block .content {
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    width: 246px;
}

.free-pos-program .left-block img.elite {
    position: absolute;
    width: 270px;
    right: 0;
    top: 84px;
}

.free-pos-program .left-block img.elite-line {
    position: absolute;
    width: 420px;
    left: 0;
    top: 84px;
}

.free-pos-program .right-block {
    margin-top: 44px;
}

.free-pos-program .right-block .title {
    font-weight: 600;
    font-size: 24px;
    color: #ffffff;
    text-align: right;
}

.free-pos-program .right-block .title span {
    color: #26ace2;
}

.free-pos-program .right-block .content {
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    width: 246px;
    float: right;
}

.free-pos-program .right-block .content span {
    font-weight: 600;
    font-style: italic;
}

.free-pos-program .right-block img.echo {
    position: absolute;
    width: 270px;
    left: 0;
    top: 101px;
}

.free-pos-program .right-block img.echo-line {
    position: absolute;
    width: 420px;
    right: 0;
    top: 84px;
}

.free-pos-program {
	padding: 68px 0 220px 0;
	.block {
		margin-top: 0;
		.title {
			font-weight: 600;
			font-size: 24px;
			color: #ffffff;
			text-align: right;
			padding-right: 18px;
			span {
				color: #26ace2;
			}
		}
		.content {
			font-size: 16px;
			line-height: 24px;
			text-align: left;
			width: 300px;
			float: right;
			margin-right: -30px;
			span {
				font-weight: 600;
				font-style: italic;
			}
		}
		img.onyx {
			position: absolute;
			width: 426px;
			left: 0;
			top: 0;
		}
		img.onyx-line {
			position: absolute;
			width: 460px;
			right: 0;
			top: 40px;
		}
	}
}

.free-emv-terminal {
	margin: 70px 0;
	.spacer {
		height: 80px;
	}
}

.free-emv-terminal h2 {
    font-weight: 400;
    font-size: 36px;
    color: #000000;
    text-align: center;
    margin: 0 0 46px 0;
}

.free-emv-terminal p.title {
    font-weight: 600;
    font-size: 30px;
    color: #0174a4;
	padding-top: 80px;
	line-height: 32px;
	&.top-40 {
		padding-top: 40px;
	}
}

.free-emv-terminal p.content {
    font-size: 24px;
    color: #3d3d3d;
    line-height: 32px;
    letter-spacing: -0.4px;
}

.free-emv-terminal p.content span {
    font-weight: 600;
    color: #26ace2;
}

.free-emv-terminal img {
	max-width: 100%;
	&.logo {
		margin-top: 30px;
		max-width: 170px;
	}
}

.free-emv-terminal a.learn-more {
    display: block;
    width: 250px;
    height: 55px;
    margin: 0 auto;
    text-align: center;
    background-color: #26ace2;
    box-shadow: 0 5px 10px 0 rgba(38, 172, 226, .2);
    border-radius: 5px;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    padding: 15px 0;
    margin-top: 35px;
    border: 1px solid #26ace2;
}

.unpa-box {
    padding: 70px 0;
    background-color: #f9fafd;
}

.unpa-box h2 {
    font-weight: 400;
    font-size: 36px;
    color: #000000;
    text-align: center;
}

.unpa-box p.unpa-about {
    font-size: 18px;
    line-height: 32px;
    color: #3d3d3d;
    margin: 0;
    padding: 0;
    padding-top: 35px;
    text-align: center;
}

.unpa-box p.unpa-about span {
    font-weight: 600;
}

.unpa-box a.learn-more {
    display: block;
    width: 250px;
    height: 55px;
    margin: 0 auto;
    text-align: center;
    background-color: #26ace2;
    box-shadow: 0 5px 10px 0 rgba(38, 172, 226, .2);
    border-radius: 5px;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    padding: 15px 0;
    margin-top: 35px;
    border: 1px solid #26ace2;
}

.unpa-box a.learn-more:hover {
    background-color: #ffffff;
    color: #26ace2;
}

#testimonial-slider {
    padding: 70px 0;
}

#testimonial-slider h2 {
    font-weight: 400;
    font-size: 36px;
    color: #000000;
    text-align: center;
    padding-bottom: 70px;
    margin-bottom: 0;
}

#testimonial-slider .testimonial-item {
    /* width: 100%; */
    box-shadow: 0 0 20px 0 rgba(145, 183, 220, .25);
    background: #fff;
    border-radius: 5px;
    padding: 15px 30px;
    width: 80%;
    margin: 0 auto;
    margin-top: 60px;
    margin-bottom: 20px;
}

#testimonial-slider .testimonial-item .icon {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    margin-top: -65px;
    border-radius: 50%;
    overflow: hidden;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(145, 182, 220, 1);
    -moz-box-shadow: 0px 0px 20px 0px rgba(145, 182, 220, 1);
    box-shadow: 0px 0px 20px 0px rgba(145, 182, 220, 1);
}

#testimonial-slider .testimonial-item .icon img {
    width: 100%;
}

#testimonial-slider .testimonial-item p.title {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: #0174a4;
    text-align: center;
    padding: 5px 0;
    padding-top: 15px;
    margin: 0;
}

#testimonial-slider .testimonial-item p.subtitle {
    font-size: 16px;
    line-height: 16px;
    color: #26ace2;
    text-align: center;
    padding: 5px 0;
    padding-top: 3px;
    margin: 0;
}

#testimonial-slider .testimonial-item p.comment {
    font-size: 16px;
    color: #3d3d3d;
    text-align: center;
    font-style: italic;
    padding: 15px 0;
    /* margin: 0; */
    width: 80%;
    margin: 0 auto;
}

#testimonial-slider .testimonial-item p.comment span.quotes-icon {
    background: url(../resources/images/quotes.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    width: 70px;
    height: 50px;
    position: absolute;
    margin-top: -70px;
}

#owl-testimonial.owl-carousel .owl-nav .owl-prev {
    position: absolute;
    left: 0;
    top: 50%;
	// background: url(../resources/images/slider-arrow-left.png);
	background: url(../resources/images/owl-arrow.svg);
    background-repeat: no-repeat;
    background-position: 10px 5px;
	background-size: 13px;
	background-color: #eaf0f8;
    height: 30px;
    width: 30px;
	opacity: 1;
	-webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
	transform: rotate(180deg);
	transition: background-color 0.5s ease;
	border-radius: 50%;
}
#owl-testimonial.owl-carousel .owl-nav .owl-prev:hover {
	background-color: #26ace2;
}

#owl-testimonial.owl-carousel .owl-nav .owl-next {
    position: absolute;
    right: 0;
    top: 50%;
    // background: url(../resources/images/slider-arrow-right.png);
	background: url(../resources/images/owl-arrow.svg);
    background-repeat: no-repeat;
    background-position: 10px 5px;
	background-size: 13px;
	background-color: #eaf0f8;
    height: 30px;
    width: 30px;
	opacity: 1;
	transition: background-color 0.5s ease;
	border-radius: 50%;
}
#owl-testimonial.owl-carousel .owl-nav .owl-next:hover {
	background-color: #26ace2;
}

#owl-testimonial.owl-carousel .owl-dots {
	margin: 0 auto;
    display: block;
    text-align: center;
}
#owl-testimonial.owl-carousel .owl-dots .owl-dot {
	background: #eaf0f8 !important;
    opacity: 1 !important;
    width: 8px !important;
    height: 8px !important;
	transition: all .2s ease-in-out;
	border-radius: 50%;
	display: inline-block;
	margin: 10px;
}
#owl-testimonial.owl-carousel .owl-dots .owl-dot.active {
	background: #26ace2 !important;
}
#owl-testimonial.owl-carousel .owl-dots .owl-dot:hover {
    transform: scale(1.1);
    background: #26ace2 !important;
}



/* Products and services */

.free-pos-program-wrapper {
    background: url(../resources/images/product-background.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    margin-top: -100px;
    padding-top: 174px;
}

.free-pos-program-wrapper h2 {
    font-weight: 400;
    font-size: 48px;
    color: #2BADE2;
    text-align: center;
    margin: 0;
    padding: 0;
    padding-bottom: 25px;
}

.free-pos-program-wrapper p.content {
    font-size: 24px;
    line-height: 32px;
    color: #3d3d3d;
    margin: 0;
    padding: 0;
    padding-top: 25px;
    padding-bottom: 30px;
    max-width: 780px;
    text-align: center;
    display: block;
    margin: 0 auto;
}

.free-pos-program-wrapper .star-item {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
    /* min-height: 100px; */
    margin-top: 58px;
}

.free-pos-program-wrapper .star-item .star-line {
    position: relative;
    height: 2px;
    width: 100%;
    background-color: #25ace1;
    top: -2px;
}

.free-pos-program-wrapper .star-item .start-icon {
    position: relative;
    background: url(../resources/images/star-icon.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 30px;
    width: 30px;
    margin: 0 auto;
    top: -18px;
}

.free-pos-program-wrapper .star-item .content {
    padding: 0 30px 30px 30px;
}

.free-pos-program-wrapper .star-item .content p {
    margin: 0;
    padding: 0;
    color: #ffffff;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    text-align: center;
}

.free-pos-program-wrapper .left-block {
    margin-top: 60px;
    min-height: 483px;
}

.free-pos-program-wrapper .left-block p.title {
    margin: 0;
    padding: 0;
    color: #ffffff;
    font-size: 30px;
    line-height: 30px;
    font-weight: 600;
    text-align: right;
}

.free-pos-program-wrapper .left-block p.title span {
    color: #26ace2;
}

.free-pos-program-wrapper .left-block .content {
    width: 60%;
    float: right;
    text-align: right;
    margin-top: 30px;
}

.free-pos-program-wrapper .left-block .content p {
    margin: 0;
    padding: 0;
    color: #ffffff;
    font-size: 16px;
    line-height: 24px;
    min-height: 150px;
}

.free-pos-program-wrapper .left-block .content p span {
    font-weight: 600;
    font-style: italic;
}

.free-pos-program-wrapper .left-block .content img {
    width: 140px;
    height: 140px;
    float: right;
}

.free-pos-program-wrapper .left-block img.echo {
    position: absolute;
    width: 100%;
    top: 280px;
    left: -188px;
}

.free-pos-program-wrapper .left-block img.echo-line {
    width: 100%;
    top: 103px;
    right: 15px;
    position: absolute;
}

.free-pos-program-wrapper .right-block {
    margin-top: 60px;
    min-height: 483px;
}

.free-pos-program-wrapper .right-block p.title {
    margin: 0;
    padding: 0;
    color: #ffffff;
    font-size: 30px;
    line-height: 30px;
    font-weight: 600;
    text-align: left;
}

.free-pos-program-wrapper .right-block p.title span {
    color: #26ace2;
}

.free-pos-program-wrapper .right-block .content {
    width: 60%;
    margin-top: 30px;
}

.free-pos-program-wrapper .right-block .content p {
    margin: 0;
    padding: 0;
    color: #ffffff;
    font-size: 16px;
    line-height: 24px;
    min-height: 150px;
}

.free-pos-program-wrapper .right-block .content p span {
    font-weight: 600;
    font-style: italic;
}

.free-pos-program-wrapper .right-block .content img {
    width: 140px;
    height: 140px;
}

.free-pos-program-wrapper .right-block img.elite {
    position: absolute;
    width: 100%;
    top: 238px;
    right: -188px;
}

.free-pos-program-wrapper .right-block img.elite-line {
    width: 100%;
    top: 103px;
    left: 15px;
    position: absolute;
}

.free-pos-program-wrapper-bottom {
    background-color: #000000;
    background: url(../resources/images/home-product-ground.jpg);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
}

.free-pos-program-wrapper-bottom h2 {
    margin: 0;
    padding: 0;
    margin-top: 185px;
    margin-bottom: 10px;
    color: #fff;
    font-size: 30px;
    line-height: 30px;
    font-weight: 400;
    text-align: center;
}

.free-pos-program-wrapper {
	.spacer {
		height: 80px;
	}
	padding-bottom: 30px;
	.block {
		margin-top: 130px;
		p.title {
			margin: 0;
			padding: 0;
			color: #ffffff;
			font-size: 30px;
			line-height: 30px;
			font-weight: 600;
			text-align: right;
			margin-top: -70px;
			span {
				color: #26ace2;
			}
		}
		.content {
			width: 240px;
			float: right;
			text-align: right;
			margin-top: 30px;
			p {
				margin: 0;
				padding: 0;
				color: #ffffff;
				font-size: 16px;
				line-height: 24px;
				min-height: 150px;
				padding-bottom: 40px;
				span {
					font-weight: 600;
					font-style: italic;
				}
			}
			img {
				width: 140px;
				height: 140px;
				float: right;
			}
		}
		img.pos {
			position: absolute;
			max-width: 646px;
			top: 100px;
			left: 0;
		}
		img.line {
			width: 471px;
			top: 103px;
			right: 15px;
			position: absolute;
		}
	}
}

.innovative-features {
    background: #000000;
    padding: 53px 0;
}

.innovative-features .innovative-feature.left {
    margin: 22px 0;
}

.innovative-features .innovative-feature.left .content {
    width: 80%;
    float: left;
}

.innovative-features .innovative-feature.left .content p.title {
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    color: #26ace2;
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
    text-align: right;
}

.innovative-features .innovative-feature.left .content p.description {
    margin: 0;
    padding: 0;
    color: #ffffff;
    font-size: 16px;
    line-height: 24px;
    width: 370px;
    float: right;
    text-align: right;
    text-align-last: right;
    -moz-text-align-last: right;
}

.innovative-features .innovative-feature.left .image {
    width: 20%;
    float: left;
}

.innovative-features .innovative-feature.left .image img {
    height: 90px;
    display: block;
    margin: 0 auto;
}

.innovative-features .innovative-feature.right {
    margin: 22px 0;
}

.innovative-features .innovative-feature.right .content {
    width: 80%;
    float: left;
}

.innovative-features .innovative-feature.right .content p.title {
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    color: #26ace2;
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
}

.innovative-features .innovative-feature.right .content p.description {
    margin: 0;
    padding: 0;
    color: #ffffff;
    font-size: 16px;
    line-height: 24px;
    width: 370px;
    text-align: left;
}

.innovative-features .innovative-feature.right .image {
    width: 20%;
    float: left;
}

.innovative-features .innovative-feature.right .image img {
    height: 90px;
    display: block;
    margin: 0 auto;
}

// 404
.not-found {
	display: block;
	margin: 200px 0 150px 0;
}
.not-found h1 {
	text-align: center;
    font-size: 140px;
    color: #31adee;
}
.not-found h2 {
	text-align: center;
	padding-top: 50px;
}

// Privacy Policy
.privacy-policy {
	display: block;
	margin: 200px 0 150px 0;
}
.privacy-policy h1 {
	text-align: center;
	font-size: 40px;
	margin: 0;
    color: #31adee;
}
.privacy-policy h2 {
	text-align: center;
	font-size: 20px;
	padding-top: 10px;
	padding-bottom: 30px;
}
.privacy-policy p strong {
	font-weight: 600;
}

.no-padding {
    padding: 0;
}

img {
    max-width: 100%;
}

@media (max-width: 767px) {
	// Header logo
	.navbar-brand img {
		padding-left: 15px;
	}
	// Header menu button
	.navbar-toggle {
		margin-top: 13px;
	}
    // Header menu
    .navbar a.btn-theme-bg-ext {
        width: 95%;
        margin: 0 auto;
    } // Home header
    .home-header-section-holder .home-header-section {
        border-radius: 0;
        margin: 0;
	}
	.home-header-section .z-index {
		padding: 0;
	}
    .home-header-section-holder .home-header-section .content {
        margin-top: 150px;
        padding-bottom: 20px;
        background: url(../resources/images/home-spidometer.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center 150px;
    }
    .home-header-section-holder .home-header-section .content p {
        font-size: 18px;
    }
    .home-header-section-holder .home-header-section .content h1 {
		font-size: 40px;
		line-height: 40px;
    }
    .home-header-section-holder .home-header-section .bg-home-page {
        display: none;
    } // Header
    .header-section-holder .header-section {
        padding-top: 40px;
        border-radius: 0 !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        min-height: 0 !important;
        max-height: 800px !important;
    }
    .header-section-holder .header-section .z-index {
        width: 100%;
    }
    .header-section-holder .header-section .content {
        margin-top: 60px;
    }
    .header-section-holder .header-section .content h1.header-title {
        font-size: 32px;
        line-height: 32px;
        padding-bottom: 0;
        text-align: center;
    }
    .header-section-holder .header-section .content p.header-content {
        margin-bottom: 20px;
        font-size: 16px;
        text-align: center;
    }
    .header-section-holder .header-section .header-content-image {
        display: none !important;
    } // Footer
    .why-banner {
        background-size: cover;
        height: auto;
    }
    .why-banner .col-md-3 {
        display: none;
    }
    .why-banner .why-center .title {
        line-height: 48px;
    }
    .why-banner .why-center a.why-button {
        margin-bottom: 70px;
    } // Why Harbortouch blocks
    .why-info-item-content {
        min-height: 0;
        padding-bottom: 1px;
    } // Learn more box
    .learn-more-box .learnmore-box {
        margin-top: 70px;
    }
    .learn-more-box .learnmore-box.first {
        margin-top: 0;
    }
    .learn-more-box .divide80 {
        display: none;
    } 
	// Free pos program
    // .free-pos-program {
    //     background-position: unset;
    //     padding: 68px 0 68px 0;
    // }
    // .free-pos-program .left-block .title,
    // .free-pos-program .right-block .title {
    //     text-align: center;
    // }
    // .free-pos-program .left-block .content,
    // .free-pos-program .right-block .content {
    //     text-align: center;
    //     width: 100%;
    // }
    // .free-pos-program .left-block img.elite,
    // .free-pos-program .right-block img.echo {
    //     position: relative;
    //     width: 50%;
    //     margin: 0 auto;
    //     display: block;
    //     top: 0;
    // }
    // .free-pos-program .left-block img.elite-line,
    // .free-pos-program .right-block img.echo-line {
    //     display: none;
    // }
	.free-pos-program {
		padding-bottom: 60px;
		.block {
			margin-top: 40px;
			text-align: center;
			p.title, p.content {
				display: block;
				width: 100%;
				float: unset;
				margin: 0 autp;
				text-align: center;
			}
			img.onyx {
				max-width: 80%;
				margin: 0 auto;
				position: relative;
			}
			img.onyx-line {
				display: none;
			}
		}
	}
	// Free emv terminals
    .free-emv-terminal p.title {
        padding: 0;
        text-align: center;
    }
    .free-emv-terminal p.content {
        text-align: center;
    } // Unparalleled revenue
    .unre-box .unre-title {
        line-height: 34px;
    }
    .unre-info-item-content {
        min-height: auto;
        padding-bottom: 1px;
    } // Unparalleled revenue reward program
    .unre-reward-program {
        background-image: none;
    }
    .unre-reward-program h2 {
        text-align: center;
    }
    .unre-reward-program p {
        text-align: justify;
    }
	// Free pos program
    // .free-pos-program-wrapper .left-block img.echo {
    //     top: 180px;
    // }
    // .free-pos-program-wrapper .left-block img.echo-line,
    // .free-pos-program-wrapper .right-block img.elite-line {
    //     display: none;
    // }
	.free-pos-program-wrapper {
		padding-bottom: 0;
    	margin-bottom: -130px;
		.block {
			margin-top: 50px;
			text-align: center;
			p.title {
				text-align: center;
				margin-top: 0;
			}
			.content {
				margin: 0 auto;
				text-align: center;
				width: 100%;
				p {
					text-align: center;
					padding-top: 20px;
					padding-bottom: 20px;
					min-height: auto;
				}
				img {
					float: unset;
				}
			}
			img.pos {
				left: 0;
				top: 0;
				margin-top: 30px;
				max-width: 100%;
				position: relative;
			}
			img.line {
				display: none;
			}
		}
	}
	// Innovative features
    .innovative-features .row .col-md-6 .innovative-feature {
        clear: both;
        padding: 50px 0;
        margin: 0;
    }
    .innovative-features .innovative-feature .content p.description {
        width: 100% !important;
    } // Free Cash Register Program
    .stt-section h3.stt-title {
        margin-top: 0 !important;
        text-align: center;
    }
    .stt-section .divide80,
    .stt-section .divide50,
    .stt-section .divide40 {
        display: none;
    } // Sales tools & trainings
    .stt-subnavigation {
        display: none;
    }
    .stt-section img {
        margin-bottom: 30px;
    } // What our patners are saying
    #testimonial-slider .testimonial-item p.comment {
        width: 100%;
    }
    #testimonial-slider .testimonial-item p.comment span.quotes-icon {
        opacity: 0.4;
    }
}

@media (max-width: 991px) {
    .unre-reward-program .image-on-mobile-screen {
        display: block;
    }
    .unre-reward-program .image-on-mobile-screen img {
        padding-top: 90px;
        width: 100%;
    }
    .unre-reward-program h2 {
        padding-top: 50px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    // Header menu
    .navbar-nav>li {
        width: 16%;
    }
    .navbar-nav>li>a {
        padding: 11px 0px;
        text-align: center;
    }
    .navbar-nav>li#menu-get-started-today {
        width: 20%;
        padding-top: 13px;
    }
    .navbar-nav>li#menu-get-started-today a {
        padding: 8px 0px;
        margin-left: 5px;
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    // Header menu
    .navbar-header {
        width: 100%;
    }
    .navbar-header a.navbar-brand {
        display: block;
        width: 100%;
    }
    .navbar-header a.navbar-brand img {
        margin: 0 auto;
    }
	.navbar {
		.navbar-collapse {
			ul.navbar-nav {
				margin: 0 -30px !important;
			}
		}
	}
	.header-section-holder {
		margin-top: 50px;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
    // Home header
    .home-header-section-holder .home-header-section {
        border-radius: 0;
        margin: 0;
    }
    .home-header-section-holder .home-header-section .content {
        padding-bottom: 80px;
        // background: url(../resources/images/home-spidometer.png);
        // background-size: cover;
        // background-repeat: no-repeat;
        // background-position: center top;
    }
    // .home-header-section-holder .home-header-section .bg-home-page {
    //     display: none;
	// }
	// Header
    .header-section-holder .header-section {
        padding-top: 40px;
        border-radius: 0 !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        min-height: 0 !important;
        max-height: 800px !important;
    }
    .header-section-holder .header-section .z-index {
        width: 100%;
    }
    .header-section-holder .header-section .content {
        margin-top: 160px;
        margin-bottom: 40px;
    }
    .header-section-holder .header-section .content h1.header-title {
        padding-bottom: 0;
        text-align: center;
    }
    .header-section-holder .header-section .content p.header-content {
        text-align: center;
    }
    .header-section-holder .header-section .header-content-image {
        display: none !important;
    } // Footer
    .why-banner {
        background-size: cover;
        height: auto;
    }
    .why-banner .col-md-3 {
        display: none;
    }
    .why-banner .why-center .title {
        line-height: 48px;
    }
    .why-banner .why-center a.why-button {
        margin-bottom: 70px;
    } // Why Harbortouch blocks
    .why-info-item-content {
        min-height: 0;
        padding-bottom: 1px;
    } // Learn more box
    .learn-more-box {
        margin-top: 70px;
    }
    .learn-more-box .learnmore-box {
        margin-top: 70px;
    }
    .learn-more-box .divide80 {
        display: none;
    } 
	// Free pos program
    // .free-pos-program {
    //     background-position: unset;
    //     padding: 68px 0 68px 0;
    // }
    // .free-pos-program .left-block .title,
    // .free-pos-program .right-block .title {
    //     text-align: center;
    // }
    // .free-pos-program .left-block .content,
    // .free-pos-program .right-block .content {
    //     text-align: center;
    //     width: 100%;
    // }
    // .free-pos-program .left-block img.elite,
    // .free-pos-program .right-block img.echo {
    //     position: relative;
    //     width: 100%;
    //     margin: 0 auto;
    //     display: block;
    //     top: 0;
    // }
    // .free-pos-program .left-block img.elite-line,
    // .free-pos-program .right-block img.echo-line {
    //     display: none;
    // }
	// Free emv terminals
    .free-emv-terminal p.title {
        padding: 0;
        text-align: center;
    }
    .free-emv-terminal p.content {
        text-align: center;
    } // Unparalleled revenue
    .unre-box .unre-title {
        line-height: 34px;
    }
    .unre-info-item-content {
        min-height: auto;
        padding-bottom: 1px;
    } // Unparalleled revenue reward program
    .unre-reward-program {
        background-image: none;
    }
    .unre-reward-program h2 {
        text-align: center;
    }
    .unre-reward-program p {
        text-align: justify;
    }
	// Free pos program
    .free-pos-program-wrapper .star-item .content {
        padding: 0 0 30px 0;
    }
    .free-pos-program-wrapper p.title {
        font-size: 24px !important;
    }
    // .free-pos-program-wrapper .left-block .content,
    // .free-pos-program-wrapper .right-block .content {
    //     width: 80% !important;
    // }
    // .free-pos-program-wrapper .left-block img.echo {
    //     top: 250px;
    // }
    // .free-pos-program-wrapper .right-block img.elite {
    //     top: 220px;
    // }
	.free-pos-program-wrapper {
		.block {
			img.pos {
				left: -115px;
			}
			img.line {
				width: 380px;
			}
		}
	}
    .free-pos-program-wrapper-bottom {
        margin-top: -90px;
    }
	// Innovative features
    .innovative-features .row .col-md-6 .innovative-feature {
        clear: both;
        padding: 50px 0;
        margin: 0;
    }
    .innovative-features .innovative-feature .content p.description {
        width: 100% !important;
    } // Free Cash Register Program
    .stt-section h3.stt-title {
        margin-top: 0 !important;
        text-align: center;
    }
    .stt-section .divide80,
    .stt-section .divide50,
    .stt-section .divide40 {
        display: none;
    } // Sales tools & trainings
    .stt-subnavigation {
        display: none;
    } // What our patners are saying
    #testimonial-slider .testimonial-item p.comment {
        width: 100%;
    }
    #testimonial-slider .testimonial-item p.comment span.quotes-icon {
        opacity: 0.4;
    }
}

@media (min-width: 991px) and (max-width: 1199px) {
    // Home header
    .home-header-section {
        margin-left: -30% !important;
        margin-right: -30% !important;
    } // Header
    .header-section-holder .header-section {
        margin-left: -30% !important;
        margin-right: -30% !important;
    }
    .header-section-holder .header-section .content {
        margin-top: 150px;
    }
    .header-section-holder .header-section .content h1.header-title {
        font-size: 40px;
        line-height: 40px;
    }
    .header-section-holder .header-section .content p.header-content {
        padding: 0;
    } // Header contact
    .header-section-holder.contact .header-section .header-content-image {
        width: 115%;
        margin-top: 130px;
    } // Free pos program
    .free-pos-program .left-block img.elite {
        width: 220px;
        top: 84px;
    }
    .free-pos-program .right-block img.echo {
        width: 220px;
        top: 106px;
    }
    .free-pos-program .left-block img.elite-line,
    .free-pos-program .right-block img.echo-line {
        width: 360px;
    }
	.free-pos-program {
		.block {
			img.onyx-line {
				width: 400px;
			}
		}
	}
	// Free pos program wrapper
    // .free-pos-program-wrapper .col-sm-6.col-md-5 {
    //     width: 50% !important;
    // }
    // .free-pos-program-wrapper .col-md-2 {
    //     display: none;
    // }
	.free-pos-program-wrapper {
		.block {
			img.pos {
				left: -115px;
			}
		}
	}

	// Free Cash Register Program
    .stt-section .divide80,
    .stt-section .divide50,
    .stt-section .divide40 {
        display: none;
    } // Sales tools and training menu
    .stt-section h3.stt-title {
        margin-top: 0 !important;
    }
    .stt-subnavigation .button-holder a {
        font-size: 14px;
    } // Free evm terminals
    .free-emv-terminal p.title {
        padding-top: 0;
    } // Why banner (footer)
    .why-banner {
        background-size: cover;
    }
    .why-banner .why-left-device {
        width: 160%;
        margin-left: -50px;
        margin-top: 30px;
    }
    .why-banner .why-right-device {
        width: 160%;
        margin-right: -50px;
        margin-top: 30px;
    }
    .why-banner .why-left-device img {
        position: relative;
        margin-left: 0;
    }
    .why-banner .why-right-device img {
        position: relative;
        margin-right: 0;
    }
    .why-banner .why-center {
        margin-top: 30px;
    }
    .why-banner .why-center .title {
        line-height: 48px;
    } // Unmatched revenue reward program
    .unre-reward-program {
        background-size: 50%;
    }
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .why-banner .col-md-3 {
        width: 20%;
    }
    .why-banner .col-md-6 {
        width: 60%;
    }
    .why-banner {
        height: auto !important;
        background-size: 100% 70%;
    }
    .why-banner .why-center {
        margin-top: 120px;
    }
    .why-banner .why-center a.why-button {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .why-banner .why-left-device {
        width: 150%;
        position: absolute;
        top: 60px;
        left: -48px;
    }
    .why-banner .why-right-device {
        width: 150%;
        position: absolute;
        top: 50px;
        left: 5px;
    }
    .why-banner .why-left-device img {
        margin: 0;
    }
}


@media (min-width: 475px) and (max-width: 600px) {
	// Free pos program images
	.free-pos-program-wrapper .left-block img.echo {
		top: 130px;
		width: 90%;
	}
	.free-pos-program-wrapper .right-block img.elite {
		top: 170px;
		width: 90%;
	}
}
@media (min-width: 601px) and (max-width: 767px) {
	// Free pos program images
	.free-pos-program-wrapper .left-block img.echo {
		top: 100px;
		width: 70%;
	}
	.free-pos-program-wrapper .right-block img.elite {
		top: 140px;
		width: 70%;
	}
	.free-pos-program-wrapper .right-block {
		min-height: 420px;
	}
}

@media (min-width : 1401px) and (max-width: 1600px){
  .why-banner {
    background-size: inherit;
  }
  .why-banner .why-left-device {
    position: absolute;
    top: 0;
    width: 130%;
    left: -100px;
  }
  .why-banner .why-right-device {
    position: absolute;
    top: -15px;
    width: 130%;
  }
}
@media (min-width : 1601px) and (max-width: 1800px){
.why-banner {
    background-size: cover;
  }
  .why-banner .why-left-device {
    position: absolute;
    top: 0;
    width: 130%;
    left: -100px;
  }
  .why-banner .why-right-device {
    position: absolute;
    top: -15px;
    width: 130%;
  }
  .why-banner .why-left-device img {
    margin-top: -8%;
  }
  .why-banner .why-right-device img {
    margin-top: -7%;
  }
}
@media (min-width : 1801px) {
.why-banner {
    background-size: cover;
  }
  .why-banner .why-left-device {
    position: absolute;
    top: 0;
    width: 130%;
    left: -100px;
  }
  .why-banner .why-right-device {
    position: absolute;
    top: -15px;
    width: 130%;
  }
  .why-banner .why-left-device img {
    margin-top: -18%;
  }
  .why-banner .why-right-device img {
    margin-top: -17%;
  }
}